.description {
    background-color: #fff;
    padding: 100px 0;
    @include mediaw(768px) {
        padding: 50px 0;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mediaw(1092px) {
            justify-content: space-around;
        }
        @include mediaw(600px) {
            justify-content: space-between;
        }
    }
    &__item {
        max-width: 262px;
        padding: 0 5px;
        @include mediaw(768px) {
            width: calc(50% - 10px);
        }
        .image-block {
            width: 250px;
            height: 250px;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 50%;
            @include mediaw(576px) {
                width: 140px;
                height: 140px;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text {
            text-align: center;
            font-family: $fontOpenSansSemibold;
            font-size: 20px;
            line-height: 30px;
            color: #404040;
            margin-top: 30px;
            @include mediaw(480px) {
                margin-top: 5px;
                font-size: 14px;
            }
        }
        &:nth-child(5), &:nth-child(6), &:nth-child(7), &:last-child {
            margin-top: 60px;
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
        &:nth-child(3) {
            @include mediaw(830px) {
                margin-top: 40px;
            }
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
        &:nth-child(4) {
            @include mediaw(1092px) {
                margin-top: 40px;
            }
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
    }

    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
}

.look {
    &__title {
        margin-bottom: 40px !important;
    }
    &__video {
        width: 850px;
        margin: 0 auto;
        height: 480px;
        @include mediaw(900px) {
            width: 100%;
        }
        @include mediaw(685px) {
            height: 360px;
        }
        @include mediaw(525px) {
            height: 205px;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .price__row {
        margin-top: 40px;
    }
}

.information {
    padding: 100px 0 0;
    font-family: $fontOpenSansRegular;
    font-size: 24px;
    line-height: 30px;
    color: #404040;
    @include mediaw(768px) {
        font-size: 18px;
        line-height: 26px;
        padding: 50px 0;
    }
    strong {
        font-family: $fontOpenSansSemibold;
    }
    &__row {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: 33.33334%;
        height: 400px;
        @include mediaw(992px) {
            width: 50%;
            height: 350px;
        }
        @include mediaw(576px) {
            height: 250px;
        }
        @include mediaw(480px) {
            height: 200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.reviews {
    padding: 100px 0;
    background-color: #fff;
    @include mediaw(768px) {
        padding: 75px 0;
    }
    @include mediaw(480px) {
        padding: 35px 0;
    }
    &__slider--wrapper {
        position: relative;
        @include mediaw(1300px) {
            padding: 0 45px;
        }
        @include mediaw(480px) {
            padding: 0 20px;
        }
        .reviews__buttons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -60px;
            width: calc(100% + 120px);
            display: flex;
            justify-content: space-between;
            z-index: 1;
            @include mediaw(1300px) {
                left: -10px;
                width: calc(100% + 20px);
            }
            @include mediaw(480px) {
                left: -5px;
                width: calc(100% + 10px);
            }
            .reviews__buttons-prev, .reviews__buttons-next {
                width: 30px;
                height: 50px;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                @include mediaw(480px) {
                    width: 15px;
                    height: 25px;
                }
            }
            .reviews__buttons-prev {
                background-image: url(../img/arrow-left.svg);
            }
            .reviews__buttons-next {
                background-image: url(../img/arrow-right.svg);
            }
        }
    }
    &__slider {
        z-index: 1;
    }
    .slick-list {
        margin: 0 -15px;
    }
    .slick-dots {
        bottom: -35px;
        li {
            width: 15px;
            height: 15px;
            margin: 0 10px;
            @include mediaw(480px) {
                margin: 0 5px;
            }
            button {
                width: 15px;
                height: 15px;
                &::before {
                    font-size: 15px;
                    width: 15px;
                    height: 15px;
                    color: #cacaca;
                    opacity: 1;
                    @include mediaw(480px) {
                        font-size: 13px;
                    }
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: #f21d80;
                    }
                }
            }
        }
    }
    &__slide {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-radius: 10px;
        margin: 150px 15px 20px;
        padding: 150px 0 40px;
        position: relative;
        @include mediaw(768px) {
            margin: 125px 15px 20px;
            padding: 125px 0 40px;
        }
        @include mediaw(680px) {
            margin: 100px 15px 20px;
            padding: 100px 0 40px;
        }
        @include mediaw(576px) {
            margin: 125px 15px 20px;
            padding: 125px 0 40px;
        }
        @include mediaw(480px) {
            padding-bottom: 15px;
        }
        @include mediaw(420px) {
            margin: 125px 15px 20px;
            padding: 125px 0 40px;
        }
        .image-block {
            width: 300px;
            height: 300px;
            position: absolute;
            top: -150px;
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
            border-radius: 50%;
            @include mediaw(768px) {
                width: 250px;
                height: 250px;
                top: -125px;
            }
            @include mediaw(680px) {
                width: 200px;
                height: 200px;
                top: -100px;
            }
            @include mediaw(576px) {
                width: 250px;
                height: 250px;
                top: -125px;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .name, .text {
            padding: 0 30px;
            @include mediaw(480px) {
                padding: 0 15px;
            }
        }
        .name {
            margin-top: 40px;
            color: #f21d80;
            font-family: $fontRobotoRegular;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            @include mediaw(480px) {
                margin-top: 15px;
                font-size: 24px;
                line-height: 36px;
            }
        }
        .text {
            margin-top: 20px;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 24px;
            color: #404040;
            @include mediaw(480px) {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
    .price__row {
        margin-top: 75px;
    }
    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
}

.images {
    &__row {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: 33.33334%;
        height: 400px;
        @include mediaw(992px) {
            width: 50%;
            height: 350px;
        }
        @include mediaw(576px) {
            height: 250px;
        }
        @include mediaw(480px) {
            height: 200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.characteristic {
    background-color: #fff;
    padding: 100px 0;
    @include mediaw(480px) {
        padding: 50px 0;
    }
    .list-row {
        display: flex;
        justify-content: space-between;
        @include mediaw(991px) {
            flex-wrap: wrap;
        }
    }
    &__list {
        width: calc(50% - 15px);
        font-family: $fontOpenSansRegular;
        font-size: 16px;
        line-height: 22px;
        color: #404040;
        @include mediaw(991px) {
            width: 100%;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 20px;
        }
        li {
            display: flex;
            justify-content: space-between;
            padding: 4px 15px;
            background-color: #f4f4f4;
            &:nth-child(2n) {
                background-color: #fff;
            }
            p:first-child {
                width: 50%;
            }
            p:last-child {
                width: 50%;
                text-align: right;
            }
        }
    }
    // .mirrors-row {
    //     display: flex;
    //     justify-content: space-around;
    //     margin-top: 20px;
    //     &__item {
    //         width: 33.33334%;
    //         img {
    //             max-width: 100%;
    //             display: block;
    //             margin: 0 auto;
    //         }
    //     }
    // }
    .price__row {
        margin-top: 20px;
    }
    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
}

.form {
    background-repeat: no-repeat;
    background-image: url(../img/form-girl-bg.jpg);
    background-size: cover;
    background-color: #fff;
    background-position-x: -300px;
    color: #fff;
    padding: 35px 0;
    @include mediaw(1630px) {
        background-position-x: -400px;
    }
    @include mediaw(1435px) {
        background-position-x: -500px;
    }
    @include mediaw(1235px) {
        background-position-x: -600px;
    }
    @include mediaw(1110px) {
        background-position-x: -700px;
    }
    @include mediaw(1010px) {
        background-position-x: -721px;
    }
    @include mediaw(960px) {
        background-position-x: -800px;
    }
    @include mediaw(900px) {
        background-position-x: -840px;
    }
    @include mediaw(860px) {
        background-position-x: -865px;
    }
    @include mediaw(768px) {
        padding: 17.5px 0;
        background-position-x: calc(100% - 290px);
    }
    @include mediaw(480px) {
        background-image: none;
        background-color: #fff;
    }
    &__block {
        border-radius: 10px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        padding: 35px 30px 30px;
        max-width: 360px;
        margin-left: auto;
        @include mediaw(768px) {
            margin: 0 auto;
        }
        @include mediaw(480px) {
            padding: 17.5px 15px 20px;
        }
    }
    &__title {
        font-size: 26px !important;
        margin-bottom: 0 !important;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    form {
        display: block;
        .input-wrapper {
            position: relative;
            width: 100%;
            &:last-child {
                @include mediaw(480px) {
                    margin-top: 15px;
                }
            }
        }
        .input-wrapper .floating-label {
            position: absolute;
            pointer-events: none;
            top: 30px;
            left: 0;
            transition: 0.2s ease all;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            color: #212121;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 38px;
            }
        }
        .input-wrapper input:focus ~ .floating-label,
        .input-wrapper input:not(:focus):valid ~ .floating-label{
            top: 0;
            font-size: 14px;
        }
        .inputs-wrapper {
            display: flex;
            justify-content: space-between;
            @include mediaw(480px) {
                flex-wrap: wrap;
            }
        }
        input {
            display: block;
            width: 100%;
            &:not([type="submit"]) {
                height: 70px;
                background-color: transparent;
                border-bottom: 1px solid #212121;
                font-family: $fontFuturaBook;
                font-size: 25px;
                line-height: 40px;
                color: #212121;
                padding-top: 25px;
                @include mediaw(480px) {
                    padding-top: 15px;
                    height: 75px;
                }
            }
            &[type="submit"] {
                background-color: #f21d80;
                border-radius: 5px;
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
                font-size: 20px;
                color: #fff;
                width: 100%;
                height: 50px;
                font-family: $fontOpenSansRegular;
                cursor: pointer;
                margin-top: 30px;
                @include mediaw(600px) {
                    margin: 15px auto 0;
                }
            }
            &[type="radio"] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
        }
        .color--wrapper {
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 40px;
            color: #212121;
            margin: 15px auto 0;
            text-align: center;
            max-width: 222px;
            @include mediaw(480px) {
                font-size: 13px;
                line-height: 20px;
            }
            div {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
            .color-item {
                display: block;
                width: 68px;
                height: 68px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                cursor: pointer;
                &-name {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 13px;
                    background-color: #fff;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    border: 1px solid #000;
                    line-height: 14px;
                    padding: 3px;
                    border-radius: 10px;
                    opacity: 0;
                    transition: opacity .3s;
                    &::after {
                        content: " ";
                        display: block;
                        width: 7px;
                        height: 7px;
                        position: absolute;
                        left: 50%;
                        bottom: -5px;
                        transform: translateX(-50%) rotate(-45deg);
                        border-left: 1px solid #000;
                        border-bottom: 1px solid #000;
                        background-color: #fff;
                    }
                }
                &:hover .color-item-name {
                    opacity: 1;
                }
                @include mediaw(480px) {
                    padding: 5px 0 5px 30px;
                }
                @include mediaw(480px) {
                    width: 50px;
                    height: 50px;
                }
                &:first-child {
                    background-image: url(../img/choose-gold.jpg);
                }
                &:nth-child(2) {
                    background-image: url(../img/choose-red.jpg);
                }
                &:nth-child(3) {
                    background-image: url(../img/choose-blue.jpg);
                }
                input:checked ~ .radioBtn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.69);
                    background-color: #ffffff;
                    @include mediaw(480px) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
    &__price {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        font-family: $fontOpenSansRegular;
        font-size: 19px;
        line-height: 24px;
        position: relative;
        left: -30px;
        background-color: #ffe0ee;
        width: calc(100% + 60px);
        padding: 15px 20px;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 22px;
            left: -15px;
            width: calc(100% + 30px);
            padding: 10px 25px;
        }
        @include mediaw(330px) {
            font-size: 13px;
        }
        .old, .new {
            // width: calc(50% - 11px);
            color: #531230;
            span {
                font-size: 30px;
                line-height: 40px;
                display: inline-block;
                color: #212121;
                // margin-top: 20px;
                @include mediaw(374px) {
                    font-size: 29px;
                    line-height: initial;
                }
            }
        }
        .old span {
            font-family: $fontFuturaLight;
            position: relative;
            &::after {
                content: " ";
                display: block;
                position: absolute;
                left: 0;
                top: 22px;
                height: 2px;
                width: 100%;
                background-color: #f75252;
            }
        }
        .new {
            text-align: right;
            span {
                font-family: $fontFuturaBook;
            }
        }
    }
    &__order {
        margin-top: 30px;
        width: 260px;
        height: 50px;
        background-color: #f21d80;
        border-radius: 5px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 40px;
        color: #fff;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            margin: 15px auto 0;
        }
    }
}

.advantages {
    background-color: #f483b7;
    color: #fff;
    padding: 40px 0;
    .container {
        display: flex;
        justify-content: space-between;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
    }
    &__item {
        width: calc(33.33334% - 30px);
        padding-left: 120px;
        position: relative;
        background-repeat: no-repeat;
        @include mediaw(768px) {
            width: 100%;
            padding-left: 90px;
        }
        &:first-child {
            background-image: url(../img/delivery-icon.svg);
            background-position: 20px 35px;
            @include mediaw(768px) {
                background-position: 12px 23px;
                background-size: 55px;
            }
        }
        &:nth-child(2) {
            background-image: url(../img/wallet-icon.svg);
            background-position: 27px 23px;
            @include mediaw(768px) {
                background-position: 17px 13px;
                background-size: 44px;
                margin-top: 20px;
            }
        }
        &:last-child {
            background-image: url(../img/package-icon.svg);
            background-position: 32px 28px;
            @include mediaw(768px) {
                background-position: 20px 16px;
                margin-top: 20px;
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: solid 2px rgba(82, 17, 47,0.4);
            position: absolute;
            left: 0;
            top: 0;
            @include mediaw(768px) {
                width: 75px;
                height: 75px;
            }
        }
        &-title {
            font-family: $fontRobotoRegular;
            font-size: 24px;
            line-height: 40px;
            @include mediaw(480px) {
                font-size: 21px;
                line-height: 38px;
            }
        }
        &-description {
            margin-top: 10px;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 22px;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}