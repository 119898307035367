.header {
    background-repeat: no-repeat;
    background-image: url(../img/header-girl-bg.jpg);
    background-color: #fff;
    background-size: cover;
    background-position-x: calc(100% + 500px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1920px;
    min-height: 750px;
    margin: 0 auto;
    padding: 80px 0;
    @include mediaw(1615px) {
        background-position-x: calc(100% + 615px);
    }
    @include mediaw(1515px) {
        background-position-x: calc(100% + 665px);
    }
    @include mediaw(1415px) {
        background-position-x: calc(100% + 715px);
    }
    @include mediaw(1315px) {
        background-position-x: calc(100% + 765px);
    }
    @include mediaw(1215px) {
        background-position-x: calc(100% + 815px);
    }
    @include mediaw(1115px) {
        background-position-x: calc(100% + 865px);
    }
    @include mediaw(1015px) {
        background-position-x: calc(100% + 915px);
    }
    @include mediaw(992px) {
        display: block;
        padding: 30px 0 0;
    }
    @include mediaw(768px) {
        background-image: none;
        background-color: rgba(132, 209, 226, 0.1);
        padding: 0 0 40px;
    }
    &__title {
        max-width: 520px;
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 40px;
        color: #606060;
        position: relative;
        @include mediaw(768px) {
            text-align: center;
            max-width: initial;
            display: none;
        }
        @include mediaw(480px) {
            font-size: 27px;    
            line-height: 35px;
        }
        @include mediaw(321px) {
            font-size: 26px; 
        }
        &::after {
            content: " ";
            display: block;
            width: 70px;
            height: 1px;
            background-color: #f21d80;
            position: absolute;
            bottom: -5px;
            left: 0;
            @include mediaw(768px) {
                display: none;
            }
        }
    }
    &__description {
        font-family: $fontNexaScriptBold;
        font-size: 50px;
        line-height: 65px;
        margin-top: 40px;
        color: #f21d80;
        @include mediaw(910px) {
            font-size: 38px;
        }
        @include mediaw(768px) {
            display: none;
        }
    }
    .mobile-image {
        display: none;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        @include mediaw(768px) {
            display: block;
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
    &__list {
        margin-top: 50px;
        font-family: $fontOpenSansRegular;
        font-size: 18px;
        color: #303030;
        @include mediaw(992px) {
            width: 100%;
            margin-top: 25px;
        }
        @include mediaw(768px) {
            font-size: 14px;
            margin: 25px auto 0;
            max-width: 350px;
        }
        li {
            padding-left: 50px;
            background-repeat: no-repeat;
            background-position: 7px center;
            &:first-child {
                background-image: url(../img/battery-icon.svg);
                background-position-x: 3px;
            }
            &:nth-child(2) {
                background-image: url(../img/icon-light.svg);
            }
            &:last-child {
                background-image: url(../img/second-mirror.svg);
            }
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }
    &__price {
        margin-top: 45px;
        max-width: 300px;
        display: flex;
        justify-content: center;
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 24px;
        color: #531230;
        @include mediaw(992px) {    
            margin-top: 25px;
        }
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 22px;
        }
        .old, .new {
            span {
                font-size: 30px;
                line-height: 40px;
                display: inline-block;
                // margin-top: 20px;
            }
        }
        .old span {
            font-family: $fontFuturaLight;
            position: relative;
            &::after {
                content: " ";
                display: block;
                position: absolute;
                left: 0;
                top: 22px;
                height: 2px;
                width: 100%;
                background-color: #f75252;
            }
        }
        .new span {
            font-family: $fontFuturaBook;
        }
    }
    &__order {
        margin-top: 45px;
        width: 300px;
        height: 50px;
        background-color: #f21d80;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 40px;
        color: #fff;
        @include mediaw(992px) {
            margin-top: 15px;
        }
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            margin: 15px auto 0;
        }
    }    
}