*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

input, button {
  border: none; }
  input:focus, button:focus {
    outline: none; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'Futura PT Light';
  src: url("../fonts/FuturaPT-Light.eot");
  src: url("../fonts/FuturaPT-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Light.woff") format("woff"), url("../fonts/FuturaPT-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Light';
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Regular';
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Book';
  src: url("../fonts/FuturaPT-Book.eot");
  src: url("../fonts/FuturaPT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Book.woff") format("woff"), url("../fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Semibold';
  src: url("../fonts/OpenSans-SemiBold.eot");
  src: url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-SemiBold.woff") format("woff"), url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lobster Regular';
  src: url("../fonts/Lobster.eot");
  src: url("../fonts/Lobster.eot?#iefix") format("embedded-opentype"), url("../fonts/Lobster.woff") format("woff"), url("../fonts/Lobster.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto Regular';
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Nexa Script Bold';
  src: url("../fonts/NexaScript-Bold.eot");
  src: url("../fonts/NexaScript-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/NexaScript-Bold.woff") format("woff"), url("../fonts/NexaScript-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header {
  background-repeat: no-repeat;
  background-image: url(../img/header-girl-bg.jpg);
  background-color: #fff;
  background-size: cover;
  background-position-x: calc(100% + 500px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  max-width: 1920px;
  min-height: 750px;
  margin: 0 auto;
  padding: 80px 0; }
  @media only screen and (max-width: 1615px) {
    .header {
      background-position-x: calc(100% + 615px); } }
  @media only screen and (max-width: 1515px) {
    .header {
      background-position-x: calc(100% + 665px); } }
  @media only screen and (max-width: 1415px) {
    .header {
      background-position-x: calc(100% + 715px); } }
  @media only screen and (max-width: 1315px) {
    .header {
      background-position-x: calc(100% + 765px); } }
  @media only screen and (max-width: 1215px) {
    .header {
      background-position-x: calc(100% + 815px); } }
  @media only screen and (max-width: 1115px) {
    .header {
      background-position-x: calc(100% + 865px); } }
  @media only screen and (max-width: 1015px) {
    .header {
      background-position-x: calc(100% + 915px); } }
  @media only screen and (max-width: 992px) {
    .header {
      display: block;
      padding: 30px 0 0; } }
  @media only screen and (max-width: 768px) {
    .header {
      background-image: none;
      background-color: rgba(132, 209, 226, 0.1);
      padding: 0 0 40px; } }
  .header__title {
    max-width: 520px;
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 40px;
    color: #606060;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .header__title {
        text-align: center;
        max-width: initial;
        display: none; } }
    @media only screen and (max-width: 480px) {
      .header__title {
        font-size: 27px;
        line-height: 35px; } }
    @media only screen and (max-width: 321px) {
      .header__title {
        font-size: 26px; } }
    .header__title::after {
      content: " ";
      display: block;
      width: 70px;
      height: 1px;
      background-color: #f21d80;
      position: absolute;
      bottom: -5px;
      left: 0; }
      @media only screen and (max-width: 768px) {
        .header__title::after {
          display: none; } }
  .header__description {
    font-family: "Nexa Script Bold";
    font-size: 50px;
    line-height: 65px;
    margin-top: 40px;
    color: #f21d80; }
    @media only screen and (max-width: 910px) {
      .header__description {
        font-size: 38px; } }
    @media only screen and (max-width: 768px) {
      .header__description {
        display: none; } }
  .header .mobile-image {
    display: none;
    width: calc(100% + 30px);
    -webkit-transform: translateX(-15px);
        -ms-transform: translateX(-15px);
            transform: translateX(-15px); }
    @media only screen and (max-width: 768px) {
      .header .mobile-image {
        display: block; } }
    .header .mobile-image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
  .header__list {
    margin-top: 50px;
    font-family: "Open Sans Regular";
    font-size: 18px;
    color: #303030; }
    @media only screen and (max-width: 992px) {
      .header__list {
        width: 100%;
        margin-top: 25px; } }
    @media only screen and (max-width: 768px) {
      .header__list {
        font-size: 14px;
        margin: 25px auto 0;
        max-width: 350px; } }
    .header__list li {
      padding-left: 50px;
      background-repeat: no-repeat;
      background-position: 7px center; }
      .header__list li:first-child {
        background-image: url(../img/battery-icon.svg);
        background-position-x: 3px; }
      .header__list li:nth-child(2) {
        background-image: url(../img/icon-light.svg); }
      .header__list li:last-child {
        background-image: url(../img/second-mirror.svg); }
      .header__list li:not(:first-child) {
        margin-top: 20px; }
  .header__price {
    margin-top: 45px;
    max-width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 24px;
    color: #531230; }
    @media only screen and (max-width: 992px) {
      .header__price {
        margin-top: 25px; } }
    @media only screen and (max-width: 768px) {
      .header__price {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .header__price {
        font-size: 14px;
        line-height: 22px; } }
    .header__price .old span, .header__price .new span {
      font-size: 30px;
      line-height: 40px;
      display: inline-block; }
    .header__price .old span {
      font-family: "Futura PT Light";
      position: relative; }
      .header__price .old span::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 22px;
        height: 2px;
        width: 100%;
        background-color: #f75252; }
    .header__price .new span {
      font-family: "Futura PT Book"; }
  .header__order {
    margin-top: 45px;
    width: 300px;
    height: 50px;
    background-color: #f21d80;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 40px;
    color: #fff; }
    @media only screen and (max-width: 992px) {
      .header__order {
        margin-top: 15px; } }
    @media only screen and (max-width: 768px) {
      .header__order {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .header__order {
        margin: 15px auto 0; } }

.description {
  background-color: #fff;
  padding: 100px 0; }
  @media only screen and (max-width: 768px) {
    .description {
      padding: 50px 0; } }
  .description__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @media only screen and (max-width: 1092px) {
      .description__row {
        -ms-flex-pack: distribute;
            justify-content: space-around; } }
    @media only screen and (max-width: 600px) {
      .description__row {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
  .description__item {
    max-width: 262px;
    padding: 0 5px; }
    @media only screen and (max-width: 768px) {
      .description__item {
        width: calc(50% - 10px); } }
    .description__item .image-block {
      width: 250px;
      height: 250px;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 50%; }
      @media only screen and (max-width: 576px) {
        .description__item .image-block {
          width: 140px;
          height: 140px; } }
      .description__item .image-block img {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .description__item .text {
      text-align: center;
      font-family: "Open Sans Semibold";
      font-size: 20px;
      line-height: 30px;
      color: #404040;
      margin-top: 30px; }
      @media only screen and (max-width: 480px) {
        .description__item .text {
          margin-top: 5px;
          font-size: 14px; } }
    .description__item:nth-child(5), .description__item:nth-child(6), .description__item:nth-child(7), .description__item:last-child {
      margin-top: 60px; }
      @media only screen and (max-width: 480px) {
        .description__item:nth-child(5), .description__item:nth-child(6), .description__item:nth-child(7), .description__item:last-child {
          margin-top: 20px; } }
    @media only screen and (max-width: 830px) {
      .description__item:nth-child(3) {
        margin-top: 40px; } }
    @media only screen and (max-width: 480px) {
      .description__item:nth-child(3) {
        margin-top: 20px; } }
    @media only screen and (max-width: 1092px) {
      .description__item:nth-child(4) {
        margin-top: 40px; } }
    @media only screen and (max-width: 480px) {
      .description__item:nth-child(4) {
        margin-top: 20px; } }
  .description .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .description .price__row-block span {
      color: #212121; }
    .description .price__row-block::after {
      background-color: #eaeaea; }

.look__title {
  margin-bottom: 40px !important; }

.look__video {
  width: 850px;
  margin: 0 auto;
  height: 480px; }
  @media only screen and (max-width: 900px) {
    .look__video {
      width: 100%; } }
  @media only screen and (max-width: 685px) {
    .look__video {
      height: 360px; } }
  @media only screen and (max-width: 525px) {
    .look__video {
      height: 205px; } }
  .look__video iframe {
    display: block;
    width: 100%;
    height: 100%; }

.look .price__row {
  margin-top: 40px; }

.information {
  padding: 100px 0 0;
  font-family: "Open Sans Regular";
  font-size: 24px;
  line-height: 30px;
  color: #404040; }
  @media only screen and (max-width: 768px) {
    .information {
      font-size: 18px;
      line-height: 26px;
      padding: 50px 0; } }
  .information strong {
    font-family: "Open Sans Semibold"; }
  .information__row {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .information__item {
    width: 33.33334%;
    height: 400px; }
    @media only screen and (max-width: 992px) {
      .information__item {
        width: 50%;
        height: 350px; } }
    @media only screen and (max-width: 576px) {
      .information__item {
        height: 250px; } }
    @media only screen and (max-width: 480px) {
      .information__item {
        height: 200px; } }
    .information__item img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; }

.reviews {
  padding: 100px 0;
  background-color: #fff; }
  @media only screen and (max-width: 768px) {
    .reviews {
      padding: 75px 0; } }
  @media only screen and (max-width: 480px) {
    .reviews {
      padding: 35px 0; } }
  .reviews__slider--wrapper {
    position: relative; }
    @media only screen and (max-width: 1300px) {
      .reviews__slider--wrapper {
        padding: 0 45px; } }
    @media only screen and (max-width: 480px) {
      .reviews__slider--wrapper {
        padding: 0 20px; } }
    .reviews__slider--wrapper .reviews__buttons {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: -60px;
      width: calc(100% + 120px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      z-index: 1; }
      @media only screen and (max-width: 1300px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -10px;
          width: calc(100% + 20px); } }
      @media only screen and (max-width: 480px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -5px;
          width: calc(100% + 10px); } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        width: 30px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer; }
        @media only screen and (max-width: 480px) {
          .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
            width: 15px;
            height: 25px; } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev {
        background-image: url(../img/arrow-left.svg); }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        background-image: url(../img/arrow-right.svg); }
  .reviews__slider {
    z-index: 1; }
  .reviews .slick-list {
    margin: 0 -15px; }
  .reviews .slick-dots {
    bottom: -35px; }
    .reviews .slick-dots li {
      width: 15px;
      height: 15px;
      margin: 0 10px; }
      @media only screen and (max-width: 480px) {
        .reviews .slick-dots li {
          margin: 0 5px; } }
      .reviews .slick-dots li button {
        width: 15px;
        height: 15px; }
        .reviews .slick-dots li button::before {
          font-size: 15px;
          width: 15px;
          height: 15px;
          color: #cacaca;
          opacity: 1; }
          @media only screen and (max-width: 480px) {
            .reviews .slick-dots li button::before {
              font-size: 13px; } }
      .reviews .slick-dots li.slick-active button::before {
        color: #f21d80; }
  .reviews__slide {
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 10px;
    margin: 150px 15px 20px;
    padding: 150px 0 40px;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .reviews__slide {
        margin: 125px 15px 20px;
        padding: 125px 0 40px; } }
    @media only screen and (max-width: 680px) {
      .reviews__slide {
        margin: 100px 15px 20px;
        padding: 100px 0 40px; } }
    @media only screen and (max-width: 576px) {
      .reviews__slide {
        margin: 125px 15px 20px;
        padding: 125px 0 40px; } }
    @media only screen and (max-width: 480px) {
      .reviews__slide {
        padding-bottom: 15px; } }
    @media only screen and (max-width: 420px) {
      .reviews__slide {
        margin: 125px 15px 20px;
        padding: 125px 0 40px; } }
    .reviews__slide .image-block {
      width: 300px;
      height: 300px;
      position: absolute;
      top: -150px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      overflow: hidden;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        .reviews__slide .image-block {
          width: 250px;
          height: 250px;
          top: -125px; } }
      @media only screen and (max-width: 680px) {
        .reviews__slide .image-block {
          width: 200px;
          height: 200px;
          top: -100px; } }
      @media only screen and (max-width: 576px) {
        .reviews__slide .image-block {
          width: 250px;
          height: 250px;
          top: -125px; } }
      .reviews__slide .image-block img {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .reviews__slide .name, .reviews__slide .text {
      padding: 0 30px; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name, .reviews__slide .text {
          padding: 0 15px; } }
    .reviews__slide .name {
      margin-top: 40px;
      color: #f21d80;
      font-family: "Roboto Regular";
      font-size: 28px;
      line-height: 40px;
      text-align: center; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name {
          margin-top: 15px;
          font-size: 24px;
          line-height: 36px; } }
    .reviews__slide .text {
      margin-top: 20px;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 24px;
      color: #404040; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .text {
          margin-top: 10px;
          font-size: 14px; } }
  .reviews .price__row {
    margin-top: 75px; }
  .reviews .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .reviews .price__row-block span {
      color: #212121; }
    .reviews .price__row-block::after {
      background-color: #eaeaea; }

.images__row {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.images__item {
  width: 33.33334%;
  height: 400px; }
  @media only screen and (max-width: 992px) {
    .images__item {
      width: 50%;
      height: 350px; } }
  @media only screen and (max-width: 576px) {
    .images__item {
      height: 250px; } }
  @media only screen and (max-width: 480px) {
    .images__item {
      height: 200px; } }
  .images__item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }

.characteristic {
  background-color: #fff;
  padding: 100px 0; }
  @media only screen and (max-width: 480px) {
    .characteristic {
      padding: 50px 0; } }
  .characteristic .list-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 991px) {
      .characteristic .list-row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .characteristic__list {
    width: calc(50% - 15px);
    font-family: "Open Sans Regular";
    font-size: 16px;
    line-height: 22px;
    color: #404040; }
    @media only screen and (max-width: 991px) {
      .characteristic__list {
        width: 100%; } }
    @media only screen and (max-width: 480px) {
      .characteristic__list {
        font-size: 14px;
        line-height: 20px; } }
    .characteristic__list li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 4px 15px;
      background-color: #f4f4f4; }
      .characteristic__list li:nth-child(2n) {
        background-color: #fff; }
      .characteristic__list li p:first-child {
        width: 50%; }
      .characteristic__list li p:last-child {
        width: 50%;
        text-align: right; }
  .characteristic .price__row {
    margin-top: 20px; }
  .characteristic .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .characteristic .price__row-block span {
      color: #212121; }
    .characteristic .price__row-block::after {
      background-color: #eaeaea; }

.form {
  background-repeat: no-repeat;
  background-image: url(../img/form-girl-bg.jpg);
  background-size: cover;
  background-color: #fff;
  background-position-x: -300px;
  color: #fff;
  padding: 35px 0; }
  @media only screen and (max-width: 1630px) {
    .form {
      background-position-x: -400px; } }
  @media only screen and (max-width: 1435px) {
    .form {
      background-position-x: -500px; } }
  @media only screen and (max-width: 1235px) {
    .form {
      background-position-x: -600px; } }
  @media only screen and (max-width: 1110px) {
    .form {
      background-position-x: -700px; } }
  @media only screen and (max-width: 1010px) {
    .form {
      background-position-x: -721px; } }
  @media only screen and (max-width: 960px) {
    .form {
      background-position-x: -800px; } }
  @media only screen and (max-width: 900px) {
    .form {
      background-position-x: -840px; } }
  @media only screen and (max-width: 860px) {
    .form {
      background-position-x: -865px; } }
  @media only screen and (max-width: 768px) {
    .form {
      padding: 17.5px 0;
      background-position-x: calc(100% - 290px); } }
  @media only screen and (max-width: 480px) {
    .form {
      background-image: none;
      background-color: #fff; } }
  .form__block {
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 35px 30px 30px;
    max-width: 360px;
    margin-left: auto; }
    @media only screen and (max-width: 768px) {
      .form__block {
        margin: 0 auto; } }
    @media only screen and (max-width: 480px) {
      .form__block {
        padding: 17.5px 15px 20px; } }
  .form__title {
    font-size: 26px !important;
    margin-bottom: 0 !important; }
  .form .container {
    position: relative;
    z-index: 1; }
  .form form {
    display: block; }
    .form form .input-wrapper {
      position: relative;
      width: 100%; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper:last-child {
          margin-top: 15px; } }
    .form form .input-wrapper .floating-label {
      position: absolute;
      pointer-events: none;
      top: 30px;
      left: 0;
      -webkit-transition: 0.2s ease all;
      -o-transition: 0.2s ease all;
      transition: 0.2s ease all;
      font-family: "Open Sans Regular";
      font-size: 16px;
      color: #212121; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper .floating-label {
          font-size: 14px;
          line-height: 38px; } }
    .form form .input-wrapper input:focus ~ .floating-label,
    .form form .input-wrapper input:not(:focus):valid ~ .floating-label {
      top: 0;
      font-size: 14px; }
    .form form .inputs-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media only screen and (max-width: 480px) {
        .form form .inputs-wrapper {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
    .form form input {
      display: block;
      width: 100%; }
      .form form input:not([type="submit"]) {
        height: 70px;
        background-color: transparent;
        border-bottom: 1px solid #212121;
        font-family: "Futura PT Book";
        font-size: 25px;
        line-height: 40px;
        color: #212121;
        padding-top: 25px; }
        @media only screen and (max-width: 480px) {
          .form form input:not([type="submit"]) {
            padding-top: 15px;
            height: 75px; } }
      .form form input[type="submit"] {
        background-color: #f21d80;
        border-radius: 5px;
        -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        font-size: 20px;
        color: #fff;
        width: 100%;
        height: 50px;
        font-family: "Open Sans Regular";
        cursor: pointer;
        margin-top: 30px; }
        @media only screen and (max-width: 600px) {
          .form form input[type="submit"] {
            margin: 15px auto 0; } }
      .form form input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0; }
    .form form .color--wrapper {
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 40px;
      color: #212121;
      margin: 15px auto 0;
      text-align: center;
      max-width: 222px; }
      @media only screen and (max-width: 480px) {
        .form form .color--wrapper {
          font-size: 13px;
          line-height: 20px; } }
      .form form .color--wrapper div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-top: 10px; }
      .form form .color--wrapper .color-item {
        display: block;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        cursor: pointer; }
        .form form .color--wrapper .color-item-name {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 13px;
          background-color: #fff;
          top: 0px;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          border: 1px solid #000;
          line-height: 14px;
          padding: 3px;
          border-radius: 10px;
          opacity: 0;
          -webkit-transition: opacity .3s;
          -o-transition: opacity .3s;
          transition: opacity .3s; }
          .form form .color--wrapper .color-item-name::after {
            content: " ";
            display: block;
            width: 7px;
            height: 7px;
            position: absolute;
            left: 50%;
            bottom: -5px;
            -webkit-transform: translateX(-50%) rotate(-45deg);
                -ms-transform: translateX(-50%) rotate(-45deg);
                    transform: translateX(-50%) rotate(-45deg);
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            background-color: #fff; }
        .form form .color--wrapper .color-item:hover .color-item-name {
          opacity: 1; }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            padding: 5px 0 5px 30px; } }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            width: 50px;
            height: 50px; } }
        .form form .color--wrapper .color-item:first-child {
          background-image: url(../img/choose-gold.jpg); }
        .form form .color--wrapper .color-item:nth-child(2) {
          background-image: url(../img/choose-red.jpg); }
        .form form .color--wrapper .color-item:nth-child(3) {
          background-image: url(../img/choose-blue.jpg); }
        .form form .color--wrapper .color-item input:checked ~ .radioBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          width: 15px;
          height: 15px;
          border-radius: 50%;
          -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.69);
                  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.69);
          background-color: #ffffff; }
          @media only screen and (max-width: 480px) {
            .form form .color--wrapper .color-item input:checked ~ .radioBtn {
              width: 10px;
              height: 10px; } }
  .form__price {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Regular";
    font-size: 19px;
    line-height: 24px;
    position: relative;
    left: -30px;
    background-color: #ffe0ee;
    width: calc(100% + 60px);
    padding: 15px 20px; }
    @media only screen and (max-width: 768px) {
      .form__price {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .form__price {
        font-size: 14px;
        line-height: 22px;
        left: -15px;
        width: calc(100% + 30px);
        padding: 10px 25px; } }
    @media only screen and (max-width: 330px) {
      .form__price {
        font-size: 13px; } }
    .form__price .old, .form__price .new {
      color: #531230; }
      .form__price .old span, .form__price .new span {
        font-size: 30px;
        line-height: 40px;
        display: inline-block;
        color: #212121; }
        @media only screen and (max-width: 374px) {
          .form__price .old span, .form__price .new span {
            font-size: 29px;
            line-height: initial; } }
    .form__price .old span {
      font-family: "Futura PT Light";
      position: relative; }
      .form__price .old span::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 22px;
        height: 2px;
        width: 100%;
        background-color: #f75252; }
    .form__price .new {
      text-align: right; }
      .form__price .new span {
        font-family: "Futura PT Book"; }
  .form__order {
    margin-top: 30px;
    width: 260px;
    height: 50px;
    background-color: #f21d80;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 40px;
    color: #fff; }
    @media only screen and (max-width: 768px) {
      .form__order {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .form__order {
        margin: 15px auto 0; } }

.advantages {
  background-color: #f483b7;
  color: #fff;
  padding: 40px 0; }
  .advantages .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .advantages .container {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .advantages__item {
    width: calc(33.33334% - 30px);
    padding-left: 120px;
    position: relative;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 768px) {
      .advantages__item {
        width: 100%;
        padding-left: 90px; } }
    .advantages__item:first-child {
      background-image: url(../img/delivery-icon.svg);
      background-position: 20px 35px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:first-child {
          background-position: 12px 23px;
          background-size: 55px; } }
    .advantages__item:nth-child(2) {
      background-image: url(../img/wallet-icon.svg);
      background-position: 27px 23px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:nth-child(2) {
          background-position: 17px 13px;
          background-size: 44px;
          margin-top: 20px; } }
    .advantages__item:last-child {
      background-image: url(../img/package-icon.svg);
      background-position: 32px 28px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:last-child {
          background-position: 20px 16px;
          margin-top: 20px; } }
    .advantages__item::before {
      content: " ";
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: solid 2px rgba(82, 17, 47, 0.4);
      position: absolute;
      left: 0;
      top: 0; }
      @media only screen and (max-width: 768px) {
        .advantages__item::before {
          width: 75px;
          height: 75px; } }
    .advantages__item-title {
      font-family: "Roboto Regular";
      font-size: 24px;
      line-height: 40px; }
      @media only screen and (max-width: 480px) {
        .advantages__item-title {
          font-size: 21px;
          line-height: 38px; } }
    .advantages__item-description {
      margin-top: 10px;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 22px; }
      @media only screen and (max-width: 480px) {
        .advantages__item-description {
          font-size: 14px;
          line-height: 20px; } }

.footer {
  background-color: #f483b7;
  padding: 15px 0;
  color: #fff;
  text-align: center;
  font-family: "Open Sans Regular";
  font-size: 16px;
  line-height: 22px; }
  .footer a {
    text-decoration: underline; }
    .footer a:hover {
      text-decoration: none; }
  .footer__contacts {
    color: #000;
    opacity: .5;
    font-family: Arial;
    margin: 10px 0;
    font-weight: bold;
    font-size: 12px; }
    .footer__contacts a {
      text-decoration: none; }
      .footer__contacts a:hover {
        text-decoration: underline; }

body {
  overflow-x: hidden; }

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.section-title {
  font-family: "Futura PT Book";
  font-size: 40px;
  color: #531230;
  text-align: center;
  margin-bottom: 50px; }
  @media only screen and (max-width: 480px) {
    .section-title {
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 35px; } }

.price__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 857px;
  margin: 60px auto 0;
  border-radius: 16px;
  background-color: #ffe0ee;
  padding: 20px 40px; }
  @media only screen and (max-width: 460px) {
    .price__row {
      padding: 10px 20px; } }
  @media only screen and (max-width: 480px) {
    .price__row {
      margin: 30px auto 0; } }
  .price__row-block {
    width: 490px;
    height: 60px;
    color: #606060;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media only screen and (max-width: 600px) {
      .price__row-block {
        width: 50%; } }
    .price__row-block .old, .price__row-block .new {
      font-family: "Open Sans Regular";
      font-size: 20px;
      line-height: 24px; }
      @media only screen and (max-width: 360px) {
        .price__row-block .old, .price__row-block .new {
          font-size: 14px;
          line-height: 22px; } }
      .price__row-block .old span, .price__row-block .new span {
        font-size: 28px;
        line-height: 40px;
        display: inline-block;
        margin-top: 10px; }
        @media only screen and (max-width: 399px) {
          .price__row-block .old span, .price__row-block .new span {
            font-size: 21px; } }
    .price__row-block .old span {
      position: relative;
      font-family: "Futura PT Light"; }
      .price__row-block .old span::after {
        content: " ";
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        top: 22px;
        left: 0;
        background-color: #f75252; }
    .price__row-block .new span {
      font-family: "Futura PT Book"; }
  .price__row .price__order {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    width: 260px;
    height: 50px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #f21d80;
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 40px; }
    @media only screen and (max-width: 600px) {
      .price__row .price__order {
        width: 50%; } }
    @media only screen and (max-width: 460px) {
      .price__row .price__order {
        font-size: 17px; } }
    @media only screen and (max-width: 374px) {
      .price__row .price__order {
        font-size: 16px; } }
    @media only screen and (max-width: 349px) {
      .price__row .price__order {
        font-size: 14px; } }
